import React, { useEffect, useRef, useState } from "react";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import DangerousOutlinedIcon from "@mui/icons-material/DangerousOutlined";
import axios from "axios";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Pagination1 from "react-js-pagination";
import Select from "react-select";
import makeAnimated from "react-select/animated";
import { toast } from "react-toastify";
import "../App.css";
import API_PATH from "../Constants/api-path";
// import { Editor } from "@tinymce/tinymce-react";

import { CKEditor } from "@ckeditor/ckeditor5-react";
import {
  ClassicEditor,
  Bold,
  Essentials,
  Italic,
  Paragraph,
  Undo,
  Link,
  Heading,
  List,
  Alignment,
  Indent,
} from "ckeditor5";
import "ckeditor5/ckeditor5.css";

import { Checkbox } from "antd";
import countryList from "./CountryList";
import { setSelectedProject } from "../Redux/features/project.slice";
import { useDispatch, useSelector } from "react-redux";
import { Button, Modal } from "react-bootstrap";

export const UpdateProjectModal = ({
  open,
  setOpen,
  getProjectList = () => { },
  getholdProjectList = () => { },
  getcompleteProjectList = () => { },
}) => {
  let animatedComponents = makeAnimated();
  const loginDetails = JSON.parse(localStorage.getItem("userData"));
  const [startDate, setStartDate] = useState("");
  const [value, setValue] = useState("");
  const [inputList, setInputList] = useState([{ company: "" }]);
  const [selectList, setSelectList] = useState([{ country: "" }]);
  const [emailList, setEmailList] = useState([]);
  const [, setSpinner] = useState(false);
  const [message, setMessage] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [allDatas, setAlldatas] = useState(0);
  const [isSubscribed, setIsSubscribed] = useState(false);
  const [, setCall_recorded] = useState(0);
  const [loading1, setLoading1] = useState(false);
  const [userList, setUserList] = useState([]);

  const userData = JSON.parse(localStorage.getItem("userData"));
  const { selectedProject } = useSelector((state) => state.projectReducer);
  const headers = React.useMemo(
    () => ({
      "Content-Type": "application/json",
      Authorization: `Bearer ${userData?.token}`,
    }),
    [userData?.token]
  );

  const inputRef = useRef();
  const inputRef2 = useRef();
  const inputRef5 = useRef();
  const inputRef6 = useRef();
  const inputRef7 = useRef();
  const dispatch = useDispatch();

  const [projectDetails, setProjectDetails] = useState({
    project_title: "",
    expected_calls: "",
  });
  const handleClose = React.useCallback(() => {
    setStartDate("");
    setValue("");
    setEmailList([]);
    dispatch(setSelectedProject({}));
    setInputList([{ company: "" }]);
    setSelectList([{ country: "" }]);
    setProjectDetails({
      project_title: "",
      expected_calls: "",
    });
  }, [dispatch]);

  const handelChange = React.useCallback(
    (e) => {
      let key = e.target.name;
      let value = e.target.value;
      setProjectDetails({ ...projectDetails, [key]: value });
    },
    [projectDetails]
  );

  const handleEditorChange = React.useCallback((content) => {
    setValue(content);
  }, []);

  const handlePageChange = React.useCallback((pageNumber) => {
    setCurrentPage(pageNumber);
  }, []);

  useEffect(() => {
    if (Object?.keys(selectedProject)?.length > 0) {
      setProjectDetails({
        expected_calls: selectedProject?.expected_calls,
        project_title: selectedProject?.project_title,
      });
      setStartDate(new Date(selectedProject?.project_deadline));
      setSelectList(
        selectedProject?.country?.split(",")?.map((country) => {
          return {
            country: country,
          };
        })
      );
      setInputList(
        selectedProject?.target_companies?.split(",")?.map((comp) => {
          return {
            company: comp,
          };
        })
      );
      setEmailList(
        selectedProject?.invite_team?.split(",")?.map((team, index) => {
          let teamEmail = team?.split("@")?.length <= 1 ? `${team}@${userData?.user?.companyDomain}` : team
          return {
            label: teamEmail,
            value: teamEmail,
            key: index,
          };
        })
      );
      setValue(selectedProject?.question);
    }
  }, [selectedProject, userData?.user?.companyDomain]);

  //COMMENTED COZ NOT FINDING THIS API ON SERVER ERROR
  // useEffect(() => {
  //   getProjects();
  // }, [currentPage, getProjects]);

  const handelChecked = React.useCallback((e) => {
    if (e.target.checked) {
      setCall_recorded((current) => current + 1);
      // console.log("✅ Checkbox is checked");
    } else {
      setCall_recorded((current) => current - 1);
      // console.log("⛔️ Checkbox is NOT checked");
    }
    setIsSubscribed((current) => !current);
  }, []);

  const handleInputChangeCompany = React.useCallback(
    (e, index) => {
      const { name, value } = e.target;
      const list = [...inputList];
      list[index][name] = value;
      setInputList(list);
    },
    [inputList]
  );

  const handleInputChangeCountry = React.useCallback(
    (e, index) => {
      const { id, value } = e.target;
      const list = [...selectList];
      list[index][id] = value;
      setSelectList(list);
    },
    [selectList]
  );

  const checkEmail = React.useCallback((email) => {
    const regEx = /[@]/;
    if (!regEx.test(email)) {
      setMessage("");
    } else {
      return setMessage("Email is invalid");
    }
  }, []);

  // const handleInputChangeEmail = React.useCallback(
  //     (e, index) => {
  //         const { name, value } = e.target;
  //         const list = [...emailList];
  //         list[index][name] = value;
  //         checkEmail(list[index].email);
  //         setEmailList(list);
  //     },
  //     [checkEmail, emailList]
  // );

  const handleRemoveClick = React.useCallback(
    (data, index) => {
      if (data === "input") {
        const list = [...inputList];
        list.splice(index, 1);
        setInputList(list);
      } else if (data === "select") {
        const list = [...selectList];
        list.splice(index, 1);
        setSelectList(list);
      }
    },
    [inputList, selectList]
  );

  // handle click event of the Add button
  const handleAddClick = React.useCallback(
    (data) => {
      if (data === "input") {
        setInputList([...inputList, { company: "" }]);
      } else if (data === "select") {
        setSelectList([...selectList, { country: "" }]);
      }
    },
    [inputList, selectList]
  );

  const handleSubmit = React.useCallback(
    async (e) => {
      e.preventDefault();
      setSpinner(true);

      let countryData = [];

      let companyData = [];
      let emailData = [];
      if (loginDetails?.user?.email) {
        emailData?.push(loginDetails?.user?.email?.split("@")[0]);
      }

      for (var Country in selectList) {
        countryData?.push(selectList[Country]?.country);
      }

      for (var Company in inputList) {
        companyData?.push(inputList[Company]?.company);
      }

      for (var index in emailList) {
        if (
          emailList?.[index]?.value !== emailData[0] &&
          emailList?.[index]?.value !== ""
        ) {
          if (!emailData?.includes(emailList?.[index]?.value?.split("@")?.[0])) {
            emailData?.push(emailList?.[index]?.value?.split("@")?.[0]);
          }
        }
      }
      let payload = {
        project_title: projectDetails?.project_title,
        user_id: loginDetails?.user?._id,
        invite_team: emailData?.join(","),
        project_id: selectedProject?._id,
        // country: countryData[0],
        country: countryData?.join(","),
        target_companies: companyData?.join(","),
        question: value,
        project_deadline: startDate?.toISOString(), // Convert to ISO string
        expected_calls: projectDetails?.expected_calls,
      };
      if (
        payload.project_title === "" ||
        payload.expected_calls === "" ||
        payload.question === "" ||
        payload.project_deadline === ""
      ) {
        return toast.warning("All fields are mandatory!", {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 3000,
        });
      } else {
        <></>;
      }

      try {
        await fetch(`${API_PATH?.UPDATE_PROJECT}`, {
          method: "POST",
          headers: headers,
          body: JSON.stringify(payload),
        });
        setSpinner(false);
        setOpen(false);
        getProjectList();
        getholdProjectList();
        getcompleteProjectList();
        handleClose();
        return toast.success("Project updated successfully", {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 3000,
        });
      } catch (error) {
        setOpen(false);
        return toast.error("Something went wrong. Server error", {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 3000,
        });
      }
    },
    [
      emailList,
      getProjectList,
      getcompleteProjectList,
      getholdProjectList,
      handleClose,
      headers,
      inputList,
      loginDetails?.user?._id,
      loginDetails?.user?.email,
      projectDetails?.expected_calls,
      projectDetails?.project_title,
      selectList,
      selectedProject?._id,
      setOpen,
      startDate,
      value,
    ]
  );

  const getUsers = React.useCallback(async () => {
    axios
      .get(`${API_PATH.USER_MANAGEMENT}/${userData?.user?.company_id._id}`, {
        method: "GET",
        headers: headers,
      })
      .then((res) => {
        console.log(res, "ress");
        setUserList([...res.data.data]);
      })
      .catch((error) => {
        console.log(error);
      });
  }, [headers, userData?.user?.company_id._id]);
  useEffect(() => {
    getUsers();
  }, [getUsers]);

  return (
    <>
      <Modal
        centered
        size={"lg"}
        show={open}
        className="bt_modal"
        scrollable={true}
        onHide={() => {
          setOpen(false);
          handleClose();
        }}
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            <h2 className="mb-0">Update Project</h2>
            <p
              className="mb-0"
              style={{ color: "#526fd7", fontSize: "12px !important" }}
            >
              Detail out your requirement to launch a custom recruitment request{" "}
            </p>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="row">
            <div className="col-lg-12">
              <div className="form-group">
                <label className="form-label d-flex"> Project Title * </label>
                <input
                  type="text"
                  className="form-control"
                  name="project_title"
                  value={projectDetails.project_title}
                  onChange={handelChange}
                  ref={inputRef}
                />
              </div>
            </div>
            <div className="col-lg-6">
              <div className="form-group">
                <label className="form-label d-flex">
                  Number of Expected Calls *
                </label>
                <select
                  className="form-select"
                  aria-label="Default select example"
                  name="expected_calls"
                  value={projectDetails.expected_calls}
                  onChange={handelChange}
                  ref={inputRef2}
                >
                  <option value>Select</option>
                  <option value="1">1</option>
                  <option value="2">2</option>
                  <option value="3">3</option>
                  <option value="4">4</option>
                  <option value="5">5</option>
                  <option value="6">6</option>
                  <option value="7">7</option>
                  <option value="8">8</option>
                  <option value="9">9</option>
                  <option value="10">10</option>
                </select>
              </div>
            </div>

            <div className="col-lg-6">
              <div className="form-group">
                <label className="form-label d-flex">Project Deadline *</label>
                <DatePicker
                  style={{ cursor: "default" }}
                  inputProps={{ readOnly: true }}
                  className="form-select"
                  minDate={new Date()}
                  value={startDate}
                  selected={startDate}
                  placeholderText="MM/DD/YYYY"
                  onChange={(date) => {
                    setStartDate(date);
                  }}
                  dateFormat="dd/MM/yyyy"
                  onKeyDown={(e) => {
                    e.preventDefault();
                  }}
                />
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-6">
              <div className="form-group">
                <label
                  className="form-label d-flex"
                  style={{ color: "black", fontWeight: "500" }}
                >
                  Geography of experts
                </label>

                {selectList.map((x, i) => {
                  console.log("x: ", x);
                  return (
                    <div
                      className="box"
                      style={{
                        display: "flex",
                        alignItems: "center",
                        marginBottom: "10px",
                        width: "100%",
                      }}
                      key={i}
                    >
                      <Select
                        style={{
                          borderRadius: "10px",
                          border: "1px solid rgb(210,210,210)",
                          marginRight: "8px",
                        }}
                        closeMenuOnSelect={false}
                        components={animatedComponents}
                        options={countryList}
                        value={{ value: x.country, label: x.country }}
                        placeholder="Select Country"
                        onChange={(e) => {
                          console.log("e: ", e);
                          handleInputChangeCountry(
                            {
                              target: {
                                value: e?.value,
                                id: "country",
                              },
                            },
                            i
                          );
                        }}
                        ref={inputRef5}
                      />

                      <div className="btn-box">
                        {selectList.length !== 1 && (
                          <DangerousOutlinedIcon
                            style={{ cursor: "pointer" }}
                            className="mr10"
                            onClick={() => handleRemoveClick("select", i)}
                          />
                        )}
                        {selectList.length - 1 === i && (
                          <AddCircleOutlineIcon
                            style={{ cursor: "pointer" }}
                            onClick={() => handleAddClick("select")}
                          />
                        )}
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
            <div className="col-md-6">
              <div className="form-group mb-4">
                <label
                  className="form-label d-flex"
                  style={{ color: "black", fontWeight: "500" }}
                >
                  Target Companies
                </label>
                {inputList.map((x, i) => {
                  return (
                    <div
                      className="box"
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                        marginBottom: "10px",
                        width: "100%",
                      }}
                      key={i}
                    >
                      <input
                        style={{
                          padding: "1vh",
                          borderRadius: "10px",
                          border: "1px solid rgb(210,210,210)",
                          width: "100%",
                          marginRight: "1vh",
                        }}
                        name="company"
                        placeholder="Google"
                        value={x.company}
                        onChange={(e) => handleInputChangeCompany(e, i)}
                        ref={inputRef6}
                      />

                      <div className="btn-box">
                        {inputList.length !== 1 && (
                          <DangerousOutlinedIcon
                            className="mr10"
                            style={{ cursor: "pointer" }}
                            onClick={() => handleRemoveClick("input", i)}
                          />
                        )}
                        {inputList.length - 1 === i && (
                          <AddCircleOutlineIcon
                            style={{ cursor: "pointer" }}
                            onClick={() => handleAddClick("input")}
                          />
                        )}
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>

            <div className="col-md-12 mb-3">
              <div className="form-group">
                <label
                  htmlFor=""
                  className="form-label d-flex"
                  style={{ color: "black", fontWeight: "500" }}
                >
                  Invite your team (Optional){" "}
                </label>
                {/* {emailList.map((x, i) => {
                                    return ( */}
                <div
                // className="box"
                // style={{
                //     display: "flex",
                //     alignItems: "center",
                //     justifyContent: "space-between",
                //     marginBottom: "10px",
                // }}
                // key={i}
                >
                  {/* <div className="col-sm-11 my-1"> */}
                  {/* <label
                                                    className="sr-only"
                                                    htmlFor="inlineFormInputGroupUsername"
                                                >
                                                    Enter Email
                                                </label> */}
                  <div className="input-group">
                    {/* <input
                                                        type="text"
                                                        className="form-control"
                                                        id="inlineFormInputGroupUsername"
                                                        placeholder="Enter Email"
                                                        name="email"
                                                        value={x.email}
                                                        onChange={(e) => handleInputChangeEmail(e, i)}
                                                        ref={inputRef7}
                                                    />
                                                    <div className="input-group-prepend">
                                                        <div className="input-group-text">
                                                            {"@" +
                                                                loginDetails?.user?.email?.split("@")[1]}
                                                        </div>
                                                    </div> */}
                    <Select
                      isMulti={true}
                      className="w-100"
                      open={true}
                      // className="form-select"
                      name="Select Team Member"
                      value={emailList}
                      options={userList?.map((item, index) => ({
                        label: item?.email,
                        value: item?.email,
                        key: index,
                      }))}
                      onChange={(value) => {
                        setEmailList(value);
                      }}
                    />
                  </div>
                </div>

                {/* <div className="btn-box">
                                                {emailList.length !== 1 && (
                                                    <DangerousOutlinedIcon
                                                        style={{ cursor: "pointer" }}
                                                        className="mr10"
                                                        onClick={() => handleRemoveClick("email", i)}
                                                    />
                                                )}
                                                {emailList.length - 1 === i && (
                                                    <AddCircleOutlineIcon
                                                        style={{ cursor: "pointer" }}
                                                        onClick={() => handleAddClick("email")}
                                                    />
                                                )}
                                            </div> */}
              </div>
              {/* );
                                })} */}
              {message === "" ? (
                <></>
              ) : (
                <p className="text-danger">{message}</p>
              )}
            </div>
          </div>

          <div className="col-lg-12">
            <div className="form-group">
              <label className="form-label d-flex"> Project Overview * </label>
              <div className="position-relative ckeditor_client">
                <CKEditor
                  editor={ClassicEditor}
                  onReady={(editor) => {
                    // editorRef.current = editor;
                    console.info("Editor is ready to use!", editor);
                  }}
                  data={value}
                  config={{
                    toolbar: {
                      items: [
                        "undo",
                        "redo",
                        "|",
                        "heading",
                        "|",
                        "|",
                        "bold",
                        "italic",
                        "|",
                        "alignment:left",
                        "alignment:center",
                        "alignment:right",
                        "alignment:justify",
                        "|",
                        "bulletedList",
                        "numberedList",
                      ],
                    },
                    plugins: [
                      Bold,
                      Essentials,
                      Italic,
                      Paragraph,
                      Undo,
                      Link,
                      Heading,
                      List,
                      Alignment,
                      // BulletedList,
                      // NumberedList,
                      Indent,
                    ],
                    heading: {
                      options: [
                        {
                          model: "paragraph",
                          title: "Paragraph",
                          class: "ck-heading_paragraph",
                        },
                        {
                          model: "heading1",
                          view: "h1",
                          title: "Heading 1",
                          class: "ck-heading_heading1",
                        },
                        {
                          model: "heading2",
                          view: "h2",
                          title: "Heading 2",
                          class: "ck-heading_heading2",
                        },
                        {
                          model: "heading3",
                          view: "h3",
                          title: "Heading 3",
                          class: "ck-heading_heading3",
                        },
                        {
                          model: "heading4",
                          view: "h4",
                          title: "Heading 4",
                          class: "ck-heading_heading4",
                        },
                        {
                          model: "heading5",
                          view: "h5",
                          title: "Heading 5",
                          class: "ck-heading_heading5",
                        },
                        {
                          model: "heading6",
                          view: "h6",
                          title: "Heading 6",
                          class: "ck-heading_heading6",
                        },
                      ],
                    },
                    alignment: {
                      options: ["left", "center", "right", "justify"],
                    },
                  }}
                  onChange={(event, editor) => {
                    const newData = editor?.getData();
                    handleEditorChange(newData);
                  }}
                />
              </div>
            </div>
          </div>

          <div className="radio2">
            <Checkbox
              className="me-2"
              onChange={handelChecked}
              value={isSubscribed}
              name="call_recorded"
              style={{ color: "#526fd7" }}
              required
            ></Checkbox>

            <label style={{ color: "#526fd7" }}>
              Request calls to be recorded
            </label>
          </div>
          <div className="d-grid gap-2 col-10 mx-auto py-4">
            <Button
              id="closebtn"
              style={{
                fontStyle: "normal",
                fontWeight: "600",
                outline: "none",
                textTransform: "inherit",
              }}
              className="btn col-lg-5 mx-auto btnsm"
              data-bs-dismiss="modal"
              aria-label="Close"
              type="primary"
              onClick={handleSubmit}
            >
              Submit
            </Button>
          </div>
        </Modal.Body>
      </Modal>
      {loading1 ? (
        <>
          {allDatas > 10 ? (
            <div className="pagination-background">
              <Pagination1
                activePage={currentPage}
                itemsCountPerPage={10}
                totalItemsCount={allDatas}
                pageRangeDisplayed={10}
                onChange={handlePageChange}
                itemclassName="page-item"
                linkclassName="page-link"
              />
            </div>
          ) : (
            <></>
          )}
        </>
      ) : (
        <></>
      )}
    </>
  );
};
